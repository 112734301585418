'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleMap = void 0;
const $ = require("jquery");
const KEY = 'AIzaSyAMNoR8w0_H9j5IW6qXwM7K53WmEPKt-pk';
const google_maps_1 = require("google-maps");
const options = { /* todo */};
const GoogleMapsLoader = new google_maps_1.Loader(KEY, options);
class GoogleMap {
    constructor(PlaceData, ColorConfig, skin = 'dark', places = {}, markers = [], infoWindows = []) {
        this.PlaceData = PlaceData;
        this.ColorConfig = ColorConfig;
        this.skin = skin;
        this.places = places;
        this.markers = markers;
        this.infoWindows = infoWindows;
        this.jsonPlaceData = {};
        this.jsonMyPlaceData = {};
        this.mapCenterLatLng = { lat: 34, lng: 134 };
        let self = this;
        this.jsonPlaceData = PlaceData.jsonPlaceData;
        // 結果ページで地点情報で地図中心座標を上書き
        if ($('#jsonMyPlaceData').length > 0) {
            this.jsonMyPlaceData = PlaceData.jsonMyPlaceData;
            this.setCurrentMapLatLng(this.jsonMyPlaceData['lat'], this.jsonMyPlaceData['lng']);
        }
        else {
            // 検索ページではローカルストレージの値を読み込む
            this.mapCenterLatLng.lat = Number(localStorage.getItem('tide-level-info.map-center.lat'));
            this.mapCenterLatLng.lng = Number(localStorage.getItem('tide-level-info.map-center.lng'));
        }
        if ($('#paramGoogleMap').length > 0) {
            // 地点情報を取得（旧手法：上記jsonから取得に変えたい）
            this.getPlaceDatas();
            // GoogleMap 組み立て
            GoogleMapsLoader.load().then((google) => {
                const elm = $('.paramColorTheme');
                self.skin = elm.attr('data-color-theme');
                self.google = google;
                this.map = self.initMap();
            });
        }
    }
    setSkin(skin) {
        this.skin = skin;
    }
    getPlaceDatas() {
        const places = $('#paramGoogleMap').data('places');
        for (let key in places) {
            const place = places[key];
            this.places[place.id] = place;
        }
    }
    setCurrentMapLatLng(lat, lng) {
        this.mapCenterLatLng.lat = lat;
        this.mapCenterLatLng.lng = lng;
    }
    initMap() {
        let self = this;
        const mapArea = document.getElementById('bulletGoogleMap');
        let style = this.ColorConfig.darkStyle;
        if (self.skin == 'light') {
            style = self.ColorConfig.lightStyle;
        }
        const mapOptions = {
            center: self.mapCenterLatLng,
            zoom: 12,
            styles: style,
        };
        self.map = new self.google.maps.Map(mapArea, mapOptions);
        for (let key in this.places) {
            this.addMarker(key);
            this.addInfoWindow(key);
            // クリックイベントを登録
            this.markers[key].addListener('click', function () {
                self.infoWindows[key].open(self.map, self.markers[key]);
            });
        }
        // 移動するたびに中心位置を保存
        self.map.addListener("idle", () => {
            self.saveMapCenter();
        });
        return this.map;
    }
    addMarker(key) {
        const place = this.places[key];
        const markerOptions = {
            map: this.map,
            position: { lat: place.lat, lng: place.lng },
            label: {
                text: place.name,
                color: '#ffffff',
                fontFamily: 'sans-serif',
                fontSize: '12px'
            },
            icon: {
                url: 'shared/img/map-pin.png',
            },
        };
        this.markers[key] = new this.google.maps.Marker(markerOptions);
    }
    addInfoWindow(key) {
        const place = this.places[key];
        let infoBody = '';
        infoBody += '<div class="info-window">';
        infoBody += '<p class="info-window__name">' + this.getPlacedAreaName(key) + place.name + '</p>';
        infoBody += '<button class="info-window__btn trigSubmitFromMap btn btn-my-primary" data-place_id="' + key + '">Go</button>';
        infoBody += '</div>';
        this.infoWindows[key] = new this.google.maps.InfoWindow({
            content: infoBody
        });
    }
    // マップの中心座標を保存
    saveMapCenter() {
        const pos = this.map.getCenter().toUrlValue().split(',');
        this.setCurrentMapLatLng(Number(pos[0]), Number(pos[1]));
        localStorage.setItem('tide-level-info.map-center.lat', pos[0]);
        localStorage.setItem('tide-level-info.map-center.lng', pos[1]);
    }
    // 地点IDからエリア名を取得
    getPlacedAreaName(myPlaceId) {
        let name = '';
        let places;
        Object.keys(this.PlaceData.jsonPlaceData).forEach((areaId) => {
            places = this.PlaceData.jsonPlaceData[areaId]['places'];
            if (places[myPlaceId] != undefined) {
                name = this.PlaceData.jsonPlaceData[areaId]['name'];
            }
        });
        return name;
    }
}
exports.GoogleMap = GoogleMap;
exports.default = GoogleMap;
