'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.TideChart = void 0;
const $ = require("jquery");
const func_1 = require("./func");
const auto_1 = require("chart.js/auto");
class TideChart {
    constructor(ColorConfig) {
        this.ColorConfig = ColorConfig;
        this.tideMin = 200;
        this.tideMax = 0;
        this.jsonTideData = {};
        this.colorTheme = '';
        this.myCharts = {};
        if ($('#jsonTideData').length > 0) {
            this.jsonTideData = func_1.default.getJsonData('jsonTideData');
            this.setTideMinMax();
        }
        if ($('.bulletTideChart').length > 0) {
            this.drawCharts();
        }
    }
    drawCharts() {
        const colors = TideChart.colors[this.ColorConfig.colorTheme];
        let ctxs = $('.paramTideChart');
        let ctx, myChart, config;
        for (let i = 0; i < ctxs.length; i++) {
            ctx = ctxs.eq(i);
            config = {
                type: 'line',
                data: {
                    labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                    datasets: [{
                            label: ctx.data('day'),
                            data: this.getData(ctx.data('day')),
                            fill: false,
                            borderColor: colors.line,
                            borderWidth: 1,
                            tension: 0.3,
                            pointRadius: 0,
                        }]
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            suggestedMin: this.tideMin,
                            suggestedMax: this.tideMax,
                            ticks: {
                                stepSize: 10,
                            }
                        }
                    }
                },
                plugins: [{
                        beforeDraw: this.drawBackground
                    }],
                // 独自項目
                colorTheme: this.ColorConfig.colorTheme,
            };
            if (this.myCharts[i]) {
                this.myCharts[i].destroy();
            }
            this.myCharts[i] = new auto_1.Chart(ctx, config);
        }
    }
    drawBackground(target) {
        const colorTheme = target.config._config.colorTheme;
        const navy = TideChart.colors[colorTheme].night;
        const orange = TideChart.colors[colorTheme].afternoon;
        const sunrise = Number(target.canvas.dataset.rise);
        const sunset = Number(target.canvas.dataset.set);
        const sunrise1 = parseFloat((sunrise + 0.03).toString());
        const sunrise2 = parseFloat((sunrise + 0.07).toString());
        const sunset1 = parseFloat((sunset + 0.05).toString());
        const sunset2 = parseFloat((sunset + 0.09).toString());
        const x_scale = target.scales.x; // x軸プロパティ取得
        const y_scale = target.scales.y; // y軸プロパティ取得
        const left = x_scale.left; // x軸の左端座標取得
        const top = y_scale.top; // y軸の上端座標取得
        const width = x_scale.width; // x軸の幅取得
        const height = y_scale.height; // y軸の高さ取得
        const id = target.canvas.id;
        const cvs = document.getElementById(id); // もちろん'my_graph'で直接指定してもOK
        const ctx = cvs.getContext("2d"); //2次元描画
        let gradient = ctx.createLinearGradient(0, 0, width, 0);
        gradient.addColorStop(0, navy);
        gradient.addColorStop(sunrise1, navy);
        gradient.addColorStop(sunrise2, orange);
        gradient.addColorStop(sunset1, orange);
        gradient.addColorStop(sunset2, navy);
        gradient.addColorStop(1, navy);
        ctx.fillStyle = gradient;
        ctx.fillRect(left, top, width, height); // 四角形描画
    }
    getData(day) {
        return [
            this.jsonTideData[day].tide00,
            this.jsonTideData[day].tide01,
            this.jsonTideData[day].tide02,
            this.jsonTideData[day].tide03,
            this.jsonTideData[day].tide04,
            this.jsonTideData[day].tide05,
            this.jsonTideData[day].tide06,
            this.jsonTideData[day].tide07,
            this.jsonTideData[day].tide08,
            this.jsonTideData[day].tide09,
            this.jsonTideData[day].tide10,
            this.jsonTideData[day].tide11,
            this.jsonTideData[day].tide12,
            this.jsonTideData[day].tide13,
            this.jsonTideData[day].tide14,
            this.jsonTideData[day].tide15,
            this.jsonTideData[day].tide16,
            this.jsonTideData[day].tide17,
            this.jsonTideData[day].tide18,
            this.jsonTideData[day].tide19,
            this.jsonTideData[day].tide20,
            this.jsonTideData[day].tide21,
            this.jsonTideData[day].tide22,
            this.jsonTideData[day].tide23,
        ];
    }
    setTideMinMax() {
        let data;
        let key, I;
        Object.keys(this.jsonTideData).forEach((day) => {
            data = this.jsonTideData[day];
            for (let i = 0; i < 23; i++) {
                I = i < 10 ? '0' + i : i;
                key = 'tide' + I;
                if (data[key] < this.tideMin) {
                    this.tideMin = data[key];
                }
                if (data[key] > this.tideMax) {
                    this.tideMax = data[key];
                }
            }
        });
    }
}
exports.TideChart = TideChart;
TideChart.colors = {
    light: {
        line: "rgb(0,71,255)",
        night: "rgb(240,247,255)",
        afternoon: "rgb(255,244,236)",
    },
    dark: {
        line: '#B3B3B3',
        night: '#3A5473',
        afternoon: '#724441',
    },
};
exports.default = TideChart;
