'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
const $ = require("jquery");
// import Holiday   from './modules/holiday';
// import Calendar  from './modules/calendar';
const color_config_1 = require("./modules/color-config");
const googlemap_1 = require("./modules/googlemap");
const search_1 = require("./modules/search");
const color_theme_1 = require("./modules/color-theme");
const tidechart_1 = require("./modules/tidechart");
const place_data_1 = require("./modules/place-data");
const menu_1 = require("./modules/menu");
$(() => {
    // // 休日カレンダー
    // const HOLIDAY = new Holiday();
    // // カレンダー
    // const CALENDAR = new Calendar();
    const PLACEDATA = new place_data_1.default();
    const COLORCONFIG = new color_config_1.default();
    const GOOGLEMAP = new googlemap_1.default(PLACEDATA, COLORCONFIG);
    const TIDECHART = new tidechart_1.default(COLORCONFIG);
    new search_1.default(PLACEDATA, GOOGLEMAP);
    new color_theme_1.default(GOOGLEMAP, TIDECHART, COLORCONFIG);
    new menu_1.default();
});
