'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = void 0;
const $ = require("jquery");
class Menu {
    constructor() {
        $('.trigMenu').click(function (e) {
            e.preventDefault();
            const open = $(this).attr('data-open');
            if (open == '1') {
                $(this).attr('data-open', 0);
                $('.bulletMenu').attr('data-open', 0);
            }
            else {
                $(this).attr('data-open', 1);
                $('.bulletMenu').attr('data-open', 1);
            }
        });
        $('.trigCloseMenu').click(function () {
            $('.trigMenu').attr('data-open', 0);
            $('.bulletMenu').attr('data-open', 0);
        });
    }
}
exports.Menu = Menu;
exports.default = Menu;
