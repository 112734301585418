'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorTheme = void 0;
const $ = require("jquery");
class ColorTheme {
    constructor(GoogleMap, TideChart, ColorConfig) {
        this.GoogleMap = GoogleMap;
        this.TideChart = TideChart;
        this.ColorConfig = ColorConfig;
        this.jsonPlaceData = {};
        let self = this;
        // Skin 変更
        $('#trigChangeSkin').click(function (e) {
            e.preventDefault();
            self.changeSkin();
        });
    }
    changeSkin() {
        const changedColorTheme = this.ColorConfig.colorTheme == 'light' ? 'dark' : 'light';
        this.ColorConfig.colorTheme = changedColorTheme;
        $('.paramColorTheme').attr('data-color-theme', changedColorTheme);
        localStorage.setItem('tide-level-info.map-center.color-theme', changedColorTheme);
        if ($('#paramGoogleMap').length > 0) {
            // マップを再描画
            this.GoogleMap.setSkin(changedColorTheme);
            this.GoogleMap.initMap();
        }
        if ($('.bulletTideChart').length > 0) {
            // グラフを再描画
            this.TideChart.drawCharts();
        }
    }
}
exports.ColorTheme = ColorTheme;
exports.default = ColorTheme;
