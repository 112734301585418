'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
const $ = require("jquery");
class Search {
    // public myPosition;
    constructor(PlaceData, GoogleMap, ajaxing = false) {
        this.PlaceData = PlaceData;
        this.GoogleMap = GoogleMap;
        this.ajaxing = ajaxing;
        this.jsonPlaceData = {};
        let self = this;
        this.jsonPlaceData = PlaceData.jsonPlaceData;
        // 現在地点を選択肢に反映
        $('#trigMyPosition').click(function () {
            this.blur();
            self.googleMapGeometry();
        });
        // エリア選択
        $('#trigChangeArea').change(function () {
            const areaId = $(this).val();
            self.changeSelectPlaceOptions(areaId);
            self.panToArea(areaId);
        });
        // 地点選択
        $('#trigChangePlace').change(function () {
            const placeId = $(this).val();
            self.panToPlace(placeId);
        });
        // Map から Go
        $(document).on('click', '.trigSubmitFromMap', function () {
            const placeId = $(this).data('place_id');
            const areaId = self.getAreaIdFromPlaceId(placeId);
            self.changeSelectPlaceOptions(areaId);
            $('.bulletChangeArea').val(placeId);
            $('#bulletSearchForm').submit();
        });
        if ($('#paramGoogleMap').length > 0) {
            // 読み込み時の地図中心地を選択肢に反映
            this.refreshMapCenterToForm();
        }
    }
    refreshMapCenterToForm() {
        const myPlace = this.getMyPlace(this.GoogleMap.mapCenterLatLng);
        $('#trigChangeArea').val(myPlace.areaId);
        this.changeSelectPlaceOptions(myPlace.areaId);
        $('.bulletChangeArea').val(myPlace.placeId);
    }
    // エリアから地点選択肢入れ替え
    changeSelectPlaceOptions(areaId) {
        let options = '';
        let places = {};
        if (areaId.length > 0) {
            places = this.jsonPlaceData[areaId]['places'];
            Object.keys(places).forEach((placeId) => {
                options += '<option value="' + placeId + '">' + places[placeId].name + '</option>';
            });
        }
        else {
            let areaName = '';
            Object.keys(this.jsonPlaceData).forEach((areaId) => {
                areaName = this.jsonPlaceData[areaId]['name'];
                places = this.jsonPlaceData[areaId]['places'];
                Object.keys(places).forEach((placeId) => {
                    options += '<option value="' + placeId + '">' + areaName + places[placeId].name + '</option>';
                });
            });
        }
        $('.bulletChangeArea').html(options);
    }
    panToArea(areaId) {
        if (!areaId) {
            areaId = 1;
        }
        const places = this.jsonPlaceData[areaId]['places'];
        const firstPlaceId = Object.keys(places).shift();
        this.GoogleMap.map.panTo(new this.GoogleMap.google.maps.LatLng(places[firstPlaceId].lat, places[firstPlaceId].lng));
        this.GoogleMap.setCurrentMapLatLng(places[firstPlaceId].lat, places[firstPlaceId].lng);
    }
    panToPlace(placeId) {
        const areaId = this.getAreaIdFromPlaceId(placeId);
        const places = this.jsonPlaceData[areaId]['places'];
        this.GoogleMap.map.panTo(new this.GoogleMap.google.maps.LatLng(places[placeId].lat, places[placeId].lng));
        this.GoogleMap.setCurrentMapLatLng(places[placeId].lat, places[placeId].lng);
        $('#trigChangeArea').val(areaId);
        this.changeSelectPlaceOptions(areaId);
        $('.bulletChangeArea').val(placeId);
    }
    getAreaIdFromPlaceId(placeId) {
        let places;
        let targetAreaId;
        Object.keys(this.jsonPlaceData).forEach((areaId) => {
            places = this.jsonPlaceData[areaId].places;
            if (places[placeId] != undefined) {
                targetAreaId = areaId;
            }
        });
        return targetAreaId;
    }
    // 現在地点を取得
    googleMapGeometry() {
        const self = this;
        navigator.geolocation.getCurrentPosition((pos) => {
            // success
            const position = {
                'lat': pos.coords.latitude,
                'lng': pos.coords.longitude,
            };
            // self.myPosition = position;
            const myPlace = self.getMyPlace(position);
            self.goToMyPlace(myPlace.areaId, myPlace.placeId);
        }, (error) => {
            // error
            console.log(error);
        });
    }
    // 座標情報に一番近い地点を取得
    getMyPlace(position) {
        let myPlaceId, myAreaId;
        let minDiffSum = 100;
        let places, diffLatSum;
        Object.keys(this.jsonPlaceData).forEach((areaId) => {
            places = this.jsonPlaceData[areaId].places;
            let place;
            Object.keys(places).forEach((placeId) => {
                place = places[placeId];
                diffLatSum = Math.abs(position.lat - place.lat) + Math.abs(position.lng - place.lng);
                if (diffLatSum < minDiffSum) {
                    minDiffSum = diffLatSum;
                    myPlaceId = placeId;
                    myAreaId = areaId;
                }
            });
        });
        return { areaId: myAreaId, placeId: myPlaceId };
    }
    goToMyPlace(areaId, placeId) {
        $('#trigChangeArea').val(areaId);
        this.changeSelectPlaceOptions(areaId);
        $('.bulletChangeArea').val(placeId);
        this.panToPlace(placeId);
    }
}
exports.Search = Search;
exports.default = Search;
