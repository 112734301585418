'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceData = void 0;
const func_1 = require("./func");
const $ = require("jquery");
class PlaceData {
    constructor() {
        this.jsonPlaceData = {};
        this.jsonMyPlaceData = {};
        // Json データを取得
        if ($('#jsonPlaceData').length > 0) {
            this.jsonPlaceData = func_1.default.getJsonData('jsonPlaceData');
        }
        if ($('#jsonMyPlaceData').length > 0) {
            this.jsonMyPlaceData = func_1.default.getJsonData('jsonMyPlaceData');
        }
    }
}
exports.PlaceData = PlaceData;
exports.default = PlaceData;
